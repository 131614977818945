<template>
  <object
    ref="fan"
    type="image/svg+xml"
    class="flex-fill"
    style="max-height: 500px !important;max-width:100%!important;"
    :data="file"
  />
</template>
<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    relocate: {
      type: Boolean,
      default: false,
    },
    relocateurl: {
      type: String,
      default: "",
    },
    values: {
      type: Array,
      default: () => [],
    },
    defs: {
      type: Object,
      default: () => ({
        1: { text: 'ODVAHA', disabled: false },
        2: { text: 'VYTRVALOST', disabled: false },
        3: { text: 'OTEVŘENOST', disabled: false },
        4: { text: 'PŘEDSTAVIVOST', disabled: false },
        5: { text: 'EMPATIE', disabled: false },
        6: { text: 'NADŠENÍ', disabled: false },
        7: { text: 'ASERTIVITA', disabled: false },
        8: { text: 'ANALYTICKÉ SCHOPNOSTI', disabled: false },
        9: { text: 'SCHOPNOST ROZVÍJET SE', disabled: false },
      }),
    },
    file: {
      type: String,
      default: 'img/creatixo_fan.svg',
    },
  },
  data: () => ({
    svg: null,
    visible: false,
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      const { fan } = this.$refs;
      fan.addEventListener('load', () => {
        this.load(fan.contentDocument);
      });
    },

    selectCategory(id) {
      $('.cat_name').each(function () {
        $(this).find('a').attr('class','collapsed');
        $(this).find('a').attr('aria-expanded','false');
      });
      $('.collapse').each(function () {
        $(this).removeClass('show');
      });
      $('a').each(function () {
        if ($(this).attr('href') == "#subcategories_of_"+id) {
          $(this).click();
          $([document.documentElement, document.body]).animate({
            scrollTop: $(this).offset().top-200
          }, 1000);
        }
      });
    },

    load(svg) {
      const doc = svg.documentElement;

      // get all progress bars nodes
      const cqbars = doc.querySelectorAll('[id^=cq_bar_]');

      // get all progress bars backgrounds nodes
      const cqbarsbck = doc.querySelectorAll('[id^=cq_barbck_]');

      // get all circles nodes
      const circles = doc.querySelectorAll('[id^=circle_path_]');

      // get all category texts
      const cattexts = doc.querySelectorAll('[id^=circle_text_]');

      // get all circle texts nodes
      const cqtexts = doc.querySelectorAll('[id^=cq_text_]');

      // get center text node
      // const cqtotal = doc.querySelector('[id^=cq_total');

      // set center text node
      // cqtotal.children[0].children[0].textContent = this.total;

      // set all circle texts nodes values
      var i = 1;
      for (const cqtext of cqtexts) {
        const n = +cqtext.id.split('_')[2];
        cqtext.children[0].children[0].textContent = i;//this.values[n];//todo::Časem vrátit zpátky!!
        i++;
      }

      // set all categories text
      for (const cattext of cattexts) {
        const n = +cattext.id.split('_')[2];
        const span = cattext.children[0];
        span.textContent = this.defs[n].text;
        span.textAlign = 'center';
        span.textAnchor = 'middle';
      }

      // set all progressbars scales
      for (const cqbar of cqbars) {
        // parse progressbar order number
        const n = +cqbar.id.split('_')[2];

        // get bar original center of sizing
        const c = {
          x: +cqbar.getAttribute('sodipodi:cx'),
          y: +cqbar.getAttribute('sodipodi:cy'),
        };

        this.values[n] = 100; //todo::Časem vyhodit!!!
        // compute right scaling... bar is visible between 0.34 - 1.0
        const scale = 0.34 + ((this.values[n] || 0) / 100) * 0.66;

        // scale bar according to computed category scale
        let t = cqbar.getAttribute('transform');
        t = `${t} translate(${c.x}, ${c.y}) scale(${scale}) translate(${-c.x}, ${-c.y})`;
        cqbar.setAttribute('transform', t);
      }

      /**
       * Unified function for setting svg elements styles according to definitions.
       * @param element {HTMLElement} An involved element.
       * @param options {{ allowSetColor: boolean }} Options used to set the element style.
       */
      const setStyle = (element, options) => {
        const n = +element.id.split('_')[2];
        const definition = this.defs[n];

        if (definition.disabled) {
          element.style.opacity = 0.1;
        } else {
          element.style.cursor = 'pointer';
        }

        if (options.allowSetColor && definition.color) {
          element.style.fill = definition.color;
        }

        // eslint-disable-next-line no-undef
        $(element).click(() => {
          // eslint-disable-next-line no-undef
          if (this.relocate) {
            window.location.replace(this.relocateurl);
          }
          else {
            this.selectCategory(definition.id);
          }
          // $(svg).trigger('category', definition);
          // this.$emit('category', definition);
        });
      };

      cqbarsbck.forEach((element) => setStyle(element, { allowSetColor: true }));
      circles.forEach((element) => setStyle(element, { allowSetColor: true }));
      cqtexts.forEach(setStyle);
      cqbars.forEach((element) => setStyle(element, { allowSetColor: true }));
      cattexts.forEach(setStyle);
    },
  },
};
</script>
<style scoped>

</style>
